<template>
  <v-card :outlined="outlined" :class="classNames">
    <v-card-title> CA journalier </v-card-title>
    <v-card-subtitle> par partenaire </v-card-subtitle>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-container>
    <v-card-text
      v-else-if="
        series.length === 0 ||
        ('data' in series[0] && series[0]['data'].length === 0)
      "
      >{{ noDataText }}</v-card-text
    >
    <div v-else>
      <vue-apex-charts
        type="line"
        :height="height"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
    <v-card-text>
      <span v-show="showTooMuchSeriesMessage">
        <v-icon size="20">
          {{ icons.mdiAlert }}
        </v-icon>
        Attention, seulement 23 lignes maximum peuvent être afficher en même
        temps
      </span>
      <a href="/premium/ca-by-partner-view"
        >Voir tout dans des graphs séparés</a
      >
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { cacheTime } from "@axios";
import { mdiAlert, mdiEye } from "@mdi/js";

import useAxios from "@/hooks/useAxios";
import { chartColors, noDataText } from "@/utils/constants";
import { getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "CaByPartnerViewChart",
  components: {
    VueApexCharts,
  },
  data() {
    return {
      icons: {
        mdiAlert,
        mdiEye,
      },
      noDataText,
      loading: false,
      showTooMuchSeriesMessage: false,
      classNames: "",
      outlined: false,
      legend: true,
      series: [{ name: "", data: [] }],
      colors: ["#F44336"],
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [], // 0, 2
      },
      height: 350,
      labelsEnabled: false,
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
      xAxis: {
        categories: [],
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    endGetData() {
      this.loading = false;

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
    async getData() {
      if (this.applyFilters && !this.loading) {
        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]) || []; // Ensure categories is an array

        const queryParams = {
          group_ca: false,
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        this.loading = true;
        const response = await this.axiosGet(
          "/programmatic/detailed-revenue-monthly-report",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        if (!response.data || response.data.length === 0) {
          console.warn("No data available from the response");
          this.endGetData();

          return;
        }

        const uniquePartnerViews = [
          ...new Set(response.data.map((item) => item.partner_view_label)),
        ];

        this.colors = chartColors.slice(0, uniquePartnerViews.length);
        const caByPartnerViewAndDayData = response.data.reduce(
          (acc, { partner_view_label, date, ca }) => {
            if (!acc[partner_view_label]) acc[partner_view_label] = {};
            acc[partner_view_label][date] = ca;
            return acc;
          },
          {}
        );

        const series = [];
        for (const partnerViewName of uniquePartnerViews) {
          let serieData = [];
          for (const dayStr of categories) {
            serieData.push(
              caByPartnerViewAndDayData?.[partnerViewName]?.[dayStr] || null
            );
          }
          series.push({
            name: partnerViewName,
            data: serieData,
          });
        }

        if (!series.length || !series[0].data.length) {
          console.warn("No series data available");
          this.endGetData();

          return;
        }

        const getRevenueMaxValue =
          response.data.reduce(
            (acc, curr) => (acc > curr["ca"] ? acc : curr["ca"]),
            0
          ) * 1.1;
        this.yAxis[0].max = Math.ceil(getRevenueMaxValue);

        // Update chartOptions with xaxis and yaxis directly
        this.chartOptions = {
          ...this.chartOptions,
          chart: {
            ...this.chartOptions.chart,
            id: this.title,
            height: this.height,
          },
          legend: {
            show: this.legend,
          },
          colors: this.colors,
          dataLabels: {
            enabled: this.labelsEnabled,
          },
          stroke: this.stroke,
          xaxis: {
            categories: categories,
            labels: {
              rotate: -45,
              rotateAlways: true,
            },
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          yaxis: this.yAxis,
        };

        // this.series = [
        //   { name: "GAM", data: [5, 15, 18, 56, 23, 42] },
        //   { name: "Seedtag", data: [12, 9, 11, 45, 33, 78] },
        // ];
        // calculate total of data array
        const calculateTotal = (item) =>
          item.data.reduce((sum, value) => sum + value, 0);

        // Sort series array based on the total of the data array in descending order
        const sortedSeries = series.sort(
          (a, b) => calculateTotal(b) - calculateTotal(a)
        );

        if (series.length > 23) {
          this.series = sortedSeries.slice(0, 23);
          this.showTooMuchSeriesMessage = true;
        } else {
          this.series = sortedSeries;
        }
      }

      this.endGetData();
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
