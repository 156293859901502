<template>
  <v-card>
    <v-card-title>
      <div>
        <p class="mb-0 font-weight-bold text-2xl">{{ title }}</p>
        <small class="text--secondary text-xs text-no-wrap">{{
          subtitle
        }}</small>
      </div>
    </v-card-title>

    <v-card-text>
      <v-list>
        <v-tooltip top>
          <template class="d-flex" v-slot:activator="{ on, attrs }">
            <v-list-item class="pa-0" v-on="on" v-bind="attrs">
              <v-list-item-avatar
                class="v-avatar-light-bg success--text justify-center"
                size="40"
                rounded
              >
                <v-icon size="30" color="success">
                  {{ icons.mdiCurrencyEur }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-semibold">
                  {{ ca.statistics }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ caLabel }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ ca.statisticsRaw }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template class="d-flex" v-slot:activator="{ on, attrs }">
            <v-list-item class="pa-0" v-on="on" v-bind="attrs">
              <v-list-item-avatar
                class="v-avatar-light-bg primary--text justify-center"
                size="40"
                rounded
              >
                <v-icon size="30" color="primary">
                  {{ icons.mdiTarget }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-semibold">
                  {{ objectif.statistics }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  objectif.title
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ objectif.statisticsRaw }}</span>
        </v-tooltip>
        <v-list-item class="pa-0">
          <v-list-item-avatar
            color="primary"
            class="v-avatar-light-bg secondary--text justify-center"
            size="40"
            rounded
          >
            <v-icon size="30" color="secondary">
              {{ icons.mdiProgressCheck }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-semibold">
              {{ objectifPercent.statistics }}
            </v-list-item-title>
            <v-list-item-subtitle
              >Pourcentage de l'objectif</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiDotsVertical,
  mdiTrendingUp,
  mdiChartBar,
  mdiCurrencyEur,
  mdiTarget,
  mdiProgressCheck,
} from "@mdi/js";
export default {
  name: "CAStatsCard",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    ca: {
      type: Object,
    },
    caLabel: {
      type: Object,
      default: "CA sur la période selectionnée",
    },
    objectif: {
      type: Object,
    },
    objectifPercent: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiChartBar,
        mdiTrendingUp,
        mdiCurrencyEur,
        mdiTarget,
        mdiDotsVertical,
        mdiProgressCheck,
      },
    };
  },
};
</script>

<style scoped></style>
