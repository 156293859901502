<template>
  <v-switch inset v-model="groupCA" label="Grouper les CA"></v-switch>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "GroupCaFilter";

export default {
  name: COMPONENT_NAME,
  props: {
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["premium"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getGroupCA",
    },
    storeUpdater: {
      type: String,
      default: "updateGroupCA",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const isInitialized = ref(false);

    // =>  METHODS

    // =>  COMPUTED
    const groupCA = computed({
      get() {
        return store.getters[`${props.store}/${props.storeGetter}`];
      },
      set(e) {
        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    // =>  WATCH
    watch(groupCA, (newValue) => {
      if (isInitialized.value) {
        // Only update user changes, not initialization
        addQueryStringParam({
          param: URL_PARAM_NAMES[COMPONENT_NAME],
          value: newValue,
          // is_multiple: false,
          // is_integer: false,
          is_boolean: true,
        });
      }
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: `${props.store}/${props.storeUpdater}`,
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: groupCA.value,
        // is_multiple: false,
        // is_integer: false,
        is_boolean: true,
        // dependsOn: undefined,
      });

      isInitialized.value = true;
    });

    // =>  DATA
    return {
      groupCA,
    };
  },
};
</script>

<style></style>
